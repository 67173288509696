import copy from 'copy-text-to-clipboard'
import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ['number']

  copyToClipboard() {
    copy(this.numberTarget.dataset.number)
    this.numberTarget.classList.add('copied')
    setTimeout(() => {
      this.numberTarget.classList.remove('copied')
    }, 1500)
  }
}
