// reusable function for fetch
export function csrfToken() {
  const csrfToken = document.querySelector("[name='csrf-token']")
  return csrfToken ? csrfToken.content : null
}

export const headers = new Headers({
  'Content-Type': 'application/json',
  'Accept': 'application/json', // showing 'as JSON' in rails console
  'X-CSRF-Token': csrfToken()
})
