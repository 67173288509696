import { Controller } from "stimulus"
import { csrfToken } from "../../src/js/csrf"

export default class extends Controller {
  static targets = [
    'number',
    'message',
    'replyForm',
    'replyTitle',
    'smsReplyBtn'
  ]

  // action
  smsNew(evt) {
    evt.preventDefault()
    this.sendSms(this.numberTarget.value, this.messageTarget.value, () => {
      toastr.info('SMS sent')
      this.messageTarget.value = null
    })
  }

  // action
  showReplyForm(evt) {
    const originator = evt.currentTarget.closest('.js-sms-row').querySelector('.js-sms-originator').innerText
    this.replyFormTarget.classList.remove('hidden')
    this.replyTitleTarget.innerText = `Reply to ${originator}`
    this.smsReplyBtnTarget.setAttribute('data-originator', originator)
    const modal = evt.currentTarget.closest('.sticky__modal')
    modal.scrollTo({ left: 0, top: modal.scrollHeight, behavior: "smooth" })
  }

  // action
  smsReply(evt) {
    console.log(evt.currentTarget.dataset.originator)
    this.sendSms(evt.currentTarget.dataset.originator, this.messageTarget.value)
  }

  sendSms(number, message) {
    fetch(this.data.get('sendsmspath'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': csrfToken()
      },
      body: JSON.stringify({
        number: number,
        message: message
      })
    }).then(res => {
      if (!res.ok) { throw res }
      this.smsSuccess(number)
    }).catch(error => {
      console.error("we got an error:", error)
      this.smsFail()
    })
  }

  smsSuccess(number) {
    toastr.info(`SMS to ${number} sent`)
    this.messageTarget.value = null
    if (this.hasReplyFormTarget) this.replyFormTarget.classList.add('hidden')
  }

  smsFail() {
    toastr.error('SMS Failed')
  }

}
