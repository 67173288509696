import { Controller } from "stimulus"
import { csrfToken } from "../../src/js/csrf"

export default class extends Controller {

  //action
  makeCall(evt) {
    const destination = evt.currentTarget.dataset.number
    const callpath = this.data.get('callpath')

    $.confirm({
      title: 'Call',
      content:
        // TODO: rails partial with changeable by admin values
        `<div class="radio-confirm">
          <div class="radio">
            <label>
              <input type="radio" name="optionsRadios" id="optionsRadios1" value="2018480">
              2018480 Emma/Showroom
            </label>
          </div>
          <div class="radio">
            <label>
              <input type="radio" name="optionsRadios" id="optionsRadios2" value="2018481">
              2018481 Steve/Big Office
            </label>
          </div>
          <div class="radio">
            <label>
              <input type="radio" name="optionsRadios" id="optionsRadios3" value="2018700">
              2018700 Steve Home
            </label>
          </div>
          <div class="radio">
            <label>
              <input type="radio" name="optionsRadios" id="optionsRadios3" value="2018701">
              2018701 Emma Home
            </label>
          </div>
        </div>`,
      confirmButton: 'Call',
      confirmButtonClass: 'btn-info',
      icon: 'fa fa-question-circle',
      animation: 'scale',
      animationClose: 'top',
      opacity: 0.5,
      confirm: () => {
        const extension = $('input[type="radio"]:checked').val()
        fetch(callpath, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-CSRF-Token': csrfToken()
          },
          body: JSON.stringify({
            extension: extension,
            destination: destination
          })
        }).then(res => {
          if (!res.ok) { throw res }
          toastr.info('Call initiated')
        }).catch(error => console.error("we got an error:", error))
      },
    })
  }

}
