import { Controller } from "stimulus"
import SlimSelect from 'slim-select'
import autoComplete from "@tarekraafat/autocomplete.js"
import '../../src/css/modules/slim_select.scss'
import '../../src/css/modules/sticky.sass'

export default class extends Controller {
  static targets = [
    'modal',
    'phoneField',
    'counter',
    'select',
    'autocom',
    'autocomResult',
    'autocomResults',
    'badge'
  ]

  initialize() {
    // close all if click outside
    document.body.addEventListener('click', evt => {
      if (!evt.target.closest('.js-sticky')) this.closeAll()
    })
  }

  // action
  openModal(event) {
    const this_modal = event.currentTarget.nextElementSibling
    this_modal.classList.toggle('hidden')
    this.modalTargets.forEach(el => {
      if (el !== this_modal) el.classList.add('hidden')
    })
    const classList = event.currentTarget.classList
    const closestHolder = event.currentTarget.closest('.js-sticky__item').querySelector(".js-holder")
    if (classList.contains('js-sms') && !this.select) {
      this.initSlimSelect()
    } else if (classList.contains('js-search')) {
      if (!this.autocom) this.initAutocom()
      this.autocomTarget.focus()
    } else if (classList.contains('js-last-sms')) {
      this.appendLastSms(closestHolder)
    } else if (classList.contains('js-last-calls')) {
      this.appendLastCalls(closestHolder)
    }
  }

  closeAll() {
    this.modalTargets.forEach(el => el.classList.add('hidden'))
  }

  // action
  closeModal() {
    this.closeAll()
  }

  initSlimSelect() {
    this.select = new SlimSelect({
      select: this.selectTarget,
      // do not focus on search fields for small devices
      searchFocus: window.innerWidth > 768,
      placeholder: 'Select person',
      onChange: info => {
        if (info.value !== '') {
          this.phoneFieldTarget.value = info.value
        }
      }
    })

    fetch(this.data.get('smspath'))
      .then(res => {
        return res.json()
      })
      .then(json => {
        this.select.setData(json.data)
      })
  }

  initAutocom() {
    this.autocom = new autoComplete({
      selector: () => this.autocomTarget,
      data: {
        src: async () => {
          const query = this.autocomTarget.value
          const source = await fetch(this.data.get('searchpath') + '?q=' + query)
          return await source.json()
        },
        // src: [{name: 'test'}, {name: 'test_again'}],
        keys: ['fullname']
      },
      threshold: 3,
      debounce: 300,
      highlight: {
        render: true,
      },
      resultItem: {
        element: (item, data) => {
          this.autocomResultsTarget.classList.add('hidden')
           item.innerHTML = `
            <div class="autocom__reswrap">
              <span>${data.match}</span>
              <span class="autocom__type">${data.value.type}</span>
            </div>
          `;
        },
        highlight: true
      },
      resultsList: {
        element: (list, data) => {
          const info = document.createElement("p");
          if (data.results.length > 0) {
            info.innerHTML = `Displaying <strong>${data.results.length}</strong> out of <strong>${data.matches.length}</strong> results`;
          } else {
            info.innerHTML = `Found <strong>${data.matches.length}</strong> matching results for <strong>"${data.query}"</strong>`;
          }
          list.prepend(info);
        },
        noResults: true,
        maxResults: 15,
        tabSelect: true
      },
      noResults: () => {
        this.autocomResultsTarget.classList.remove('hidden')
        this.autocomResultsTarget.innerText = 'No results'
      },
      onSelection: async feedback => {
        const response = await fetch(feedback.selection.value.path)
        this.autocomResultTarget.innerHTML = await response.text()
      }
    })
  }

  // action
  counterChange(event) {
    const text_max = 160
    const text_length = event.currentTarget.value.length
    const message_amount = text_length ? Math.ceil(text_length / text_max) : 1
    const text_remaining = text_length ? message_amount * text_max - text_length : text_max
    this.counterTarget.innerHTML = `${text_remaining} characters remaining - ${message_amount} message`
  }

  appendLastSms(holder) {
    // hide badge
    this.badgeTarget.classList.add('hidden')
    fetch(this.data.get('lastsmspath'))
      .then(res => {
        return res.text()
      })
      .then(text => {
        holder.innerHTML = text
      })
  }

  appendLastCalls(holder) {
    fetch(this.data.get('lastcallspath'))
      .then(res => {
        return res.text()
      })
      .then(text => {
        holder.innerHTML = text
      })
  }

}
